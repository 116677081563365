import { FC } from "react";
import classes from "./style.module.scss";
import { IoMdRadioButtonOff } from "react-icons/io";
import { IoMdRadioButtonOn } from "react-icons/io";

const RadioButton: FC<{
  data: Array<{ [key: string]: string | number }>;
  value: string | number;
  pointer: { label: string; value: string };
  displayType?: "horizontal" | "vertical";
  labelPositon?: "right" | "left";
  onChange: (value: string | number) => void;
}> = ({
  data = [],
  value = "",
  pointer = { label: "label", value: "value" },
  displayType = "horizontal",
  labelPositon = "right",
  onChange,
}) => {
  return (
    <div className={`flex ${classes.container}`} data-view={displayType}>
      {data.map((item) => (
        <span
          key={`radio-${item[pointer.value]}`}
          className={`flex grow cursor-pointer items-center gap-1 ${classes.radio}`}
          data-position={labelPositon}
          onClick={() => {
            onChange && onChange(item[pointer.value]);
          }}
        >
          {value === item[pointer.value] ? (
            <IoMdRadioButtonOn className={`${classes.active}`} />
          ) : (
            <IoMdRadioButtonOff />
          )}
          <span className={`text-xs`}>{item[pointer.label]}</span>
        </span>
      ))}
    </div>
  );
};
export default RadioButton;
