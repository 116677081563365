import { useEffect, useState } from "react";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TNotificatons, TNotificatonsById } from "../../types/bill-book";

const useNotificationsById = () => {
    const { handleGetCallV2 } = useAxiosClient();

    const [notificationsById, setNotificationsById] = useState<TNotificatonsById | null>();

const fetchNotificationsById = (stageId:number)=>{
    handleGetCallV2<TNotificatonsById>(
        `click/bills/bill-edit/stage/caId/:clickID/stageId/${stageId}`
    ).then(({ data }) => {
        if (data) {
            setNotificationsById(data);
        } else {
            setNotificationsById(null);
        }
    });
}    
       
    return {
        notificationsById,
        fetchNotificationsById
    };
}
    export default useNotificationsById;
