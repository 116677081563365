import { useEffect, useState } from "react"; 
import useAxiosClient from "../../hooks/useAxiosClient";
import { TNotificatons, TNotificatonsById } from "../../types/bill-book";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "../../store";
import { updateNotifications } from "../../reducers/billBookSlice";

const useNotifications = () => {
  const { handleGetCallV2 } = useAxiosClient();
  const dispatch = useDispatch()

  const [notifications, setNotifications] = useState<TNotificatons[]>([]);

  const fetchNotifications = ()=>{
    handleGetCallV2<TNotificatons[]>(
      `click/bills/bill-edit/notifications/caId/:clickID`
    ).then(({ data }) => {
      console.log(data);
      if (data) {
        setNotifications(data);
        dispatch(updateNotifications(data));
      } else {
        setNotifications([]);
        dispatch(updateNotifications([]));

      }
    });

  }
    
  
  return { 
    notifications,
    fetchNotifications
  };
};
export default useNotifications;
