import { CSSProperties, FC, ReactNode } from "react";

const Select3: FC<{
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  style?: CSSProperties;
  className?: string;
}> = ({ open, children, style = {}, className = "", onClose }) => {
  return (
    <>
      <div
        data-open={open}
        className={
          `border rounded-md bg-white absolute left-0 top-[100%] z-20 w-full ${
            open === false ? "hidden" : ""
          } ` + className
        }
        style={style}
      >
        {children}
      </div>
      {open === true && (
        <div
          className="fixed top-0 left-0 h-full w-full z-10"
          onClick={() => {
            onClose();
          }}
        ></div>
      )}
    </>
  );
};
export default Select3;
