import { FC, ReactElement } from "react";
import classes from "./style.module.scss";
const Chip: FC<{
  label: string;
  icon?: ReactElement;
  iconPosition?: "left" | "right";
  color?: "primary" | "danger";
  onClick?: () => void;
}> = ({ label, icon, iconPosition = "right", color = "primary", onClick }) => {
  return (
    <span
      className={`${classes.chip} cursor-pointer`}
      data-color={color}
      onClick={onClick}
    >
      {icon && iconPosition === "left" && (
        <span className={classes.iconContainer}>{icon}</span>
      )}
      <span className={`text-xs px-2 py-1`}>{label}</span>
      {icon && iconPosition === "right" && (
        <span className={classes.iconContainer}>{icon}</span>
      )}
    </span>
  );
};
export default Chip;
