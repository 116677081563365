import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../layout/layout";
import PageLoading from "../components/commons/page-loading";
import {
  getMandiDetails,
  getMandiLogoDetails,
  getSystemSettings,
} from "../actions/billCreationService";
import {
  IS_AUTH,
  LOGIN_RESPONSE,
  PLAN_STATUS,
  REGISTER_DATA,
} from "../constants/storage-keys";
import { numberOfDays, numberOfDaysSell } from "../reducers/billViewSlice";
import { useDispatch } from "react-redux";
import { authActions } from "../reducers/authSlice";

const Splash = lazy(() => import("../modules/splash"));
const Login = lazy(() => import("../modules/login/login"));
const LoginForm = lazy(() => import("../modules/login/loginForm"));
const SmartBoard = lazy(() => import("../modules/smartboard/smartboard"));
const BuyBillBook = lazy(() => import("../modules/buy_bill_book/buyBillBook"));
const BillView = lazy(() => import("../modules/buy_bill_book/billView"));
const LanguageSelection = lazy(() =>
  import("../modules/login/languageSelection")
);
const Partner = lazy(() => import("../modules/partners/partner"));
const MyProfile = lazy(() => import("../modules/my_profile/myProfile"));
const ProfileNew = lazy(() => import("../modules/profile"));
const Reports = lazy(() => import("../modules/reports/reports"));
const SellBillBook = lazy(() =>
  import("../modules/sell_bill_book/sellBillBook")
);
const SmartChart = lazy(() => import("../modules/smart_chart/smartChart"));
const TransportoLedger = lazy(() =>
  import("../modules/transporto_ledger/transportoLedger")
);
const Registration = lazy(() => import("../modules/registration/registration"));
const PreferredCrops = lazy(() =>
  import("../modules/registration/preferredCrops")
);
const SubscriptionPlans = lazy(() =>
  import("../modules/registration/subscriptionPlans")
);
const BuyerLedgers = lazy(() => import("../modules/ledgers/buyerLedgers"));
const SellerLedgers = lazy(() => import("../modules/ledgers/sellerLedgers"));
const Advance = lazy(() => import("../modules/advances/advance"));
const BuyBillBookV2 = lazy(() => import("../modules/buy-bill-book"));
const SellBillBookV2 = lazy(() => import("../modules/sell-bill-book"));
const SellerLedgersV2 = lazy(() =>
  import("../modules/ledgersV2/seller-ledgers")
);
const BuyerLedgersV2 = lazy(() => import("../modules/ledgersV2/buyer-ledgers"));
const SmartBoardV2 = lazy(() => import("../modules/smartboardV2"));
const TransporterLedgersV2 = lazy(() =>
  import("../modules/ledgersV2/transporter-ledger")
);
const ReportsV2 = lazy(() => import("../modules/reports_v2"));
const DailySummary = lazy(() => import("../modules/reports_v2/daily-summary"));
const GrossProfitSummary = lazy(() =>
  import("../modules/reports_v2/gross-profit")
);
const SalesSummary = lazy(() =>
  import("../modules/reports_v2/sales-reports/sales-summary")
);
const SalesByBuyer = lazy(() =>
  import("../modules/reports_v2/sales-reports/sales-by-buyer")
);
const SalesByCrop = lazy(() =>
  import("../modules/reports_v2/sales-reports/sales-by-crop")
);
const PurchaseSummary = lazy(() =>
  import("../modules/reports_v2/purchase-reports/purchase-summary")
);
const PurchaseBySeller = lazy(() =>
  import("../modules/reports_v2/purchase-reports/purchase-by-seller")
);
const PurchaseByCrop = lazy(() =>
  import("../modules/reports_v2/purchase-reports/purchase-by-crop")
);

const StockSummary = lazy(() => import("../modules/reports_v2/stock-reports"));
const RoutesConfig = () => {
  const isLocalAuth = localStorage.getItem(IS_AUTH);
  console.log("routess", isLocalAuth);
  const dispatch = useDispatch();
  const setSettingsDetails = (clickId) => {
    getMandiLogoDetails(clickId)
      .then((response) => {
        // console.log(response.data.data, "pdfdata");
        localStorage.setItem(
          "settingsData",
          JSON.stringify(response.data.data)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const setSystemSettingsDetails = (clickId) => {
    getSystemSettings(clickId)
      .then((response) => {
        // console.log(response.data.data, "billsettings");
        localStorage.setItem(
          "systemSettingsData",
          JSON.stringify(response.data.data)
        );
        if (response.data.data != null) {
          if (response.data.data.billSetting.length > 0)
            for (var i = 0; i < response.data.data.billSetting.length; i++) {
              if (
                response.data.data.billSetting[i].billType === "BUY" &&
                response.data.data.billSetting[i].formStatus === 1 &&
                response.data.data.billSetting[i].settingName === "BILL_EDIT"
              ) {
                if (response.data.data.billSetting[i].value == 0) {
                  dispatch(numberOfDays(7));
                } else if (response.data.data.billSetting[i].value == 1) {
                  dispatch(numberOfDays(15));
                }
                if (response.data.data.billSetting[i].value == 2) {
                  dispatch(numberOfDays(30));
                }
                if (response.data.data.billSetting[i].value == 3) {
                  dispatch(numberOfDays(180));
                }
                console.log(response.data.data.billSetting[i].value, "buy");
              } else if (
                response.data.data.billSetting[i].billType === "SELL" &&
                response.data.data.billSetting[i].formStatus === 1 &&
                response.data.data.billSetting[i].settingName === "BILL_EDIT"
              ) {
                if (response.data.data.billSetting[i].value == 0) {
                  dispatch(numberOfDaysSell(7));
                } else if (response.data.data.billSetting[i].value == 1) {
                  dispatch(numberOfDaysSell(15));
                }
                if (response.data.data.billSetting[i].value == 2) {
                  dispatch(numberOfDaysSell(30));
                }
                if (response.data.data.billSetting[i].value == 3) {
                  dispatch(numberOfDaysSell(180));
                }
                console.log(response.data.data.billSetting[i].value, "sell");
              }
            }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setMandiDetails = (clickId) => {
    getMandiDetails(clickId)
      .then((response) => {
        var businessDetails = response.data.data.businessDtls;
        var personalDetails = response.data.data.personalDtls;
        console.log(businessDetails, clickId, "businessDetails");
        localStorage.setItem(
          "businessDetails",
          JSON.stringify(businessDetails)
        );
        localStorage.setItem(
          "personalDetails",
          JSON.stringify(personalDetails)
        );
        dispatch(authActions.setBusinessDtls(JSON.stringify(businessDetails)));
        dispatch(authActions.setPersonalDtls(JSON.stringify(personalDetails)));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  if (isLocalAuth === "true") {
    const loginData = JSON.parse(localStorage.getItem(LOGIN_RESPONSE));
    const id = localStorage.getItem(REGISTER_DATA);
    const planStatus = localStorage.getItem(PLAN_STATUS);
    // localStorage.getItem("statusPlan") == "FAILURE"
    //   ? ""
    //   : localStorage.getItem("statusPlan");
    if (
      loginData.useStatus == "USER_REGISTRATION_PENDING" &&
      id !== loginData.caId.toString()
    ) {
      return (
        <Routes>
          <Route path="/registration" element={<Registration />} />
        </Routes>
      );
    }
    setMandiDetails(loginData.caId);
    setSettingsDetails(loginData.caId);
    setSystemSettingsDetails(loginData.caId);
    console.log("idd");
    if (
      loginData.useStatus == "USER_REGISTRATION_PENDING" &&
      id === loginData.caId.toString()
    ) {
      if (planStatus === "SUCCESS") {
        return (
          <Layout>
            <Suspense fallback={<PageLoading />}>
              <Routes>
                <Route path="/" element={<SmartBoardV2 />} />
                <Route path="/smartboard" element={<SmartBoardV2 />} />
                <Route path="/buy_bill_book" element={<BuyBillBook />} />
                <Route path="/bill_view/:billId" element={<BillView />} />
                <Route path="buyerledger" element={<BuyerLedgers />} />
                <Route path="sellerledger" element={<SellerLedgers />}></Route>
                <Route path="/partner" element={<Partner />} />
                <Route path="/myprofile" element={<MyProfile />} />
                <Route path="/myprofileNew" element={<ProfileNew />} />
                {/* <Route path="/reports" element={<Reports />} /> */}
                <Route path="/sellbillbook" element={<SellBillBook />} />
                <Route path="/smartchart" element={<SmartChart />} />
                <Route
                  path="/transportoledger"
                  element={<TransportoLedger />}
                />
                <Route path="/advance" element={<Advance />} />
                {/* <Route path="/smartboard_new" element={<SmartBoardV2 />} /> */}
                {/* <Route path="/reports_new" element={<ReportsV2 />} /> */}

                <Route path="/reports" element={<ReportsV2 />}>
                  <Route path="daily-summary" element={<DailySummary />} />
                  <Route path="gross-profit" element={<GrossProfitSummary />} />
                  <Route path="sales-reports" element={<SalesSummary />} />
                  <Route path="sales-by-buyer" element={<SalesByBuyer />} />
                  <Route path="sales-by-crop" element={<SalesByCrop />} />
                  <Route path="stock-reports" element={<StockSummary />} />
                  <Route
                    path="purchase-summary"
                    element={<PurchaseSummary />}
                  />
                  <Route
                    path="purchase-by-seller"
                    element={<PurchaseBySeller />}
                  />
                  <Route path="purchase-by-crop" element={<PurchaseByCrop />} />
                </Route>
                {/* <Route
                      path="/transportoledger"
                      element={<TransportoLedger />}
                    /> */}
              </Routes>
            </Suspense>
          </Layout>
        );
      } else {
        return (
          <Routes>
            <Route path="/preferredCrops" element={<PreferredCrops />} />
            <Route path="/plans" element={<SubscriptionPlans />} />
          </Routes>
        );
      }
    }
    return (
      <Layout>
        <Suspense fallback={<PageLoading />}>
          <Routes>
            <Route path="/" element={<SmartBoardV2 />} />
            <Route path="/smartboard" element={<SmartBoardV2 />} />
            <Route path="/buy_bill_book" element={<BuyBillBook />} />
            <Route path="/buy-bill-book" element={<BuyBillBookV2 />} />
            <Route path="/bill_view/:billId" element={<BillView />} />
            {/* <Route path="/sell_bill_view/:billId" element={<SellBillView />} /> */}
            <Route path="/seller-ledgers" element={<SellerLedgersV2 />} />
            <Route path="/buyer-ledgers" element={<BuyerLedgersV2 />} />
            <Route path="buyerledger" element={<BuyerLedgers />} />
            <Route path="sellerledger" element={<SellerLedgers />}></Route>
            <Route path="/partner" element={<Partner />} />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/myprofileNew" element={<ProfileNew />} />
            {/* <Route path="/reports" element={<Reports />} /> */}
            <Route path="/sellbillbook" element={<SellBillBook />} />
            <Route path="/sell-bill-book" element={<SellBillBookV2 />} />
            <Route path="/smartchart" element={<SmartChart />} />
            <Route path="/transportoledger" element={<TransportoLedger />} />
            <Route
              path="/transporter-ledgers"
              element={<TransporterLedgersV2 />}
            />
            <Route path="/advance" element={<Advance />} />
            {/* <Route path="/smartboard_new" element={<SmartBoardV2 />} /> */}
            <Route path="/reports" element={<ReportsV2 />}>
              <Route path="daily-summary" element={<DailySummary />} />
              <Route path="gross-profit" element={<GrossProfitSummary />} />
              <Route path="sales-summary" element={<SalesSummary />} />
              <Route path="sales-by-buyer" element={<SalesByBuyer />} />
              <Route path="sales-by-crop" element={<SalesByCrop />} />
              <Route path="purchase-summary" element={<PurchaseSummary />} />
              <Route path="purchase-by-seller" element={<PurchaseBySeller />} />
              <Route path="purchase-by-crop" element={<PurchaseByCrop />} />
              <Route path="stock-reports" element={<StockSummary />} />
            </Route>
            {/* </Route> */}
            {/* <Route
                  path="/transportoledger"
                  element={<TransportoLedger />}
                /> */}
          </Routes>
        </Suspense>
      </Layout>
    );
  }
  if (isLocalAuth === null || isLocalAuth !== "true") {
    return (
      <Suspense fallback={<div>loading ....</div>}>
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/languageSelection" element={<LanguageSelection />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login_form" element={<LoginForm />} />
        </Routes>
      </Suspense>
    );
  }
};
export default RoutesConfig;
