import { FC, ReactNode, memo } from "react";
import classes from "./style.module.scss";
import closeIcn from "../../../assets/images/close.svg";

const Dailouge: FC<{
  position?: "center" | "top-right";
  animation?: "zoomin" | "slide-from-top";
  size: { height: string; width: string };
  open: boolean | "";
  children?: ReactNode;
  onClose?: () => void;
  disableBackground?: boolean;
}> = ({
  open,
  onClose,
  children = null,
  position = "top-right",
  animation = "zoomin",
  size,
  disableBackground = false,
}) => {
  let positionStyle = {};
  if (position === "center") {
    positionStyle = {
      top: `calc(40vh - ${size.height}/2)`,
      left: `calc(50vw - ${size.width}/2)`,
    };
  }
  return (
    <>
      <div
        data-open={open}
        data-position={position}
        data-animation={animation}
        className={`fixed overflow-auto rounded-lg bg-white shadow-lg ${classes.dailog}`}
        style={{ maxHeight: size.height, width: size.width, ...positionStyle }}
      >
        {typeof onClose === "function" && (
          <img
            src={closeIcn}
            className="absolute right-2 top-2 cursor-pointer"
            onClick={onClose}
          />
        )}
        {children}
      </div>
      {disableBackground && (
        <div
          className="fixed top-0 left-0 h-full w-full"
          style={{ zIndex: 10, background: "#a4aba7a3" }}
        ></div>
      )}
    </>
  );
};
export default memo(Dailouge);
