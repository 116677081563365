import React from 'react';
import classes from './style.module.scss';

const Card = ({ children, styles, className }) => {
  return (
    <section className={`${classes.container} ${className}`} style={styles}>
      {children}
    </section>
  );
};

export default Card;
