import React from 'react';
import dropDown from '../../../assets/images/dropdown.svg';
import classes from './style.module.scss';

function Select(
  {
    id,
    label,
    options,
    required,
    className,
    error = '',
    defaultSelected,
    ...props
  },
  ref
) {
  console.log('errorsss', error);
  return (
    <div className="w-full">
      {label && (
        <label htmlFor={id} className={classes.label}>
          {label} <span data-required={required} />
        </label>
      )}
      <div className={classes.selectContainer}>
        <select
          {...props}
          id={id}
          ref={ref}
          className={`${classes.select} ${className}`}
          data-error={error.length > 0}
        >
          {defaultSelected && (
            <option disabled selected hidden value={defaultSelected}>
              {defaultSelected}
            </option>
          )}

          {options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <img src={dropDown} alt="drop_down_icon" />
        {error.length > 0 && (
          <span className={classes.errorMessage}>{error}</span>
        )}
      </div>
    </div>
  );
}

export default React.forwardRef(Select);
