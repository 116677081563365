import { FC, useState, useRef, useEffect, memo } from "react";
import { BsChevronDown, BsCheckLg } from "react-icons/bs";
import { createPortal } from "react-dom";
import classes from "./style.module.scss";
type TOnoSelect = {
  options: Array<{ label: string; value: string }>;
  value?: string | number;
  onChange?: (selectedOption: { label: string; value: string }) => void;
};
const gap = 0;
export const OnoSelect: FC<TOnoSelect> = ({
  options,
  value = "",
  onChange,
}) => {
  const ref = useRef<HTMLElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);
  const [display, setDisplay] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    bottom: 0,
  });
  const onSelect = (option: { label: string; value: string }) => {
    onChange && onChange(option);
    setDisplay(false);
  };
  useEffect(() => {
    if (value) {
      let sel_option = { label: "", value: "" };
      for (let option of options) {
        if (option.value === value) {
          sel_option = { label: option.label, value: option.value };
          break;
        }
      }
      setSelectedOption(sel_option);
    }
  }, [value, options]);
  useEffect(() => {
    if (
      ref.current !== null &&
      optionsRef.current !== null &&
      display === true
    ) {
      let windowsize = [window.innerWidth, window.innerHeight];
      const optionsPosition = optionsRef.current.getBoundingClientRect();
      const selectBoxPosition = ref.current.getBoundingClientRect();
      let bottomSpace = windowsize[1] - selectBoxPosition.bottom;
      if (optionsPosition.height > bottomSpace) {
        setPosition({
          ...position,
          left: selectBoxPosition.left,
          top: selectBoxPosition.top - optionsPosition.height,
          width: selectBoxPosition.width,
        });
      } else {
        setPosition({
          ...position,
          left: selectBoxPosition.left,
          top: selectBoxPosition.top + selectBoxPosition.height + gap,
          width: selectBoxPosition.width,
        });
      }
    }
  }, [display]);
  return (
    <>
      <button className="w-full h-full">
        <span
          className={`flex w-full h-full items-center cursor-pointer ${classes.selectbox}`}
          ref={ref}
          onClick={() => {
            setDisplay(!display);
          }}
        >
          {selectedOption.label || " -- Select --"}
          <BsChevronDown className={`ml-auto`} />
        </span>
      </button>
      {display === true &&
        createPortal(
          <>
            <div
              onClick={() => {
                setDisplay(false);
              }}
              className="fixed h-full w-full top-0 left-0"
              style={{ zIndex: "110" }}
            ></div>
            <ul
              ref={optionsRef}
              className={`shadow-md ${classes.selectUl}`}
              style={{
                top: position.top,
                left: position.left,
                width: `${position.width}px`,
              }}
            >
              {options.map((option) => (
                <li
                  className={`flex px-2 py-1 text-sm items-center`}
                  data-value={option.value}
                  data-selected={option.value === value ? true : false}
                  onClick={() => {
                    onSelect(option);
                  }}
                >
                  {option.label}
                  {option.value === value && <BsCheckLg className="ml-auto" />}
                </li>
              ))}
            </ul>
          </>,
          document.body
        )}
    </>
  );
};

export default memo(OnoSelect);
