import { FC, ChangeEvent, FocusEvent, CSSProperties } from "react";

const Input: FC<{
  type?: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
  style?: CSSProperties;
  disabled?: boolean;
  maxLength?: number;
  allowNegativeNumber?: boolean;
}> = ({
  type = "text",
  placeholder = "",
  className = "",
  onFocus,
  onBlur,
  onChange,
  value = "",
  style = {},
  disabled = false,
  maxLength,
  allowNegativeNumber = false,
}) => {
  return (
    <input
      type={type}
      className={`w-full h-full border-0 ${className}`}
      style={{ outline: "none", ...style }}
      placeholder={placeholder}
      pattern="[0-9]*(\.[0-9]{0,2})?$"
      onChange={(e) => {
        if (!onChange) {
          return;
        }
        if (
          typeof maxLength != "undefined" &&
          e.target.value.length > maxLength
        ) {
          return;
        }
        if (type === "number") {
          let regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
          if (allowNegativeNumber === true) {
            regexp = /^[-0-9]*(\.[0-9]{0,2})?$/;
          }
          if (regexp.test(e.target.value) == true) {
            onChange(e);
          } else {
            e.target.value = parseFloat(e.target.value).toFixed(2);
            onChange(e);
          }
        } else {
          onChange(e);
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      onWheel={(e) => (e.target as HTMLElement).blur()}
    />
  );
};
export default Input;
