import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../reducers/authSlice";
import oNoLocalStorage from "../helpers/oNoLocalStorage";
import { TRootState } from "../store";
import { API_BASE_URL } from "../constants";
const useAxiosClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /** Getting data from redux store */
  const { loginResponse, isAuthenticated } = useSelector(
    (state: TRootState) => ({
      loginResponse: state.auth.loginResponse,
      isAuthenticated: state.auth.isAuthenticated,
    })
  );

  /** Setting common axios header  */
  const clientID =
    isAuthenticated === true ? loginResponse.authKeys.clientId : "";
  const clientSecret =
    isAuthenticated === true ? loginResponse.authKeys.clientSecret : "";
  const writerID =
    loginResponse?.useStatus === "WRITER" ? loginResponse?.clickId : 0;
  const clickID = loginResponse?.caId;
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
  });
  axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
  axiosInstance.defaults.headers.common["client-id"] = clientID;
  axiosInstance.defaults.headers.common["client-secret"] = clientSecret;

  /** Function to attach click id in URL */
  const handleCreateURL = (_url = "") => {
    let newURL = _url;
    if (_url.includes(":clickID") && _url.includes(":writerID")) {
      newURL = _url.replace(":clickID", clickID);
      newURL = newURL.replace(":writerID", writerID);
      return newURL;
    }

    if (_url.includes(":clickID")) {
      newURL = _url.replace(":clickID", clickID);
    }

    if (_url.includes(":writerID")) {
      newURL = _url.replace(":writerID", writerID);
    }

    return newURL;
  };

  /** Function to logout the user and navigate to login page */
  const handleLogout = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    navigate("/login");
    dispatch(authActions.logout());
    oNoLocalStorage.clear();
    //@ts-ignore
    oNoLocalStorage.set("LinkPath", "/smartboard", false);
    //@ts-ignore
    oNoLocalStorage.set("statusPlan", "FAILURE", false);
    //@ts-ignore
    oNoLocalStorage.set("LinkId", "1", false);
    //@ts-ignore
    oNoLocalStorage.set("isauth", false, false);
    window.location.reload();
  };

  /** Function to handle get requests */
  const handleGetCall = async ({ URL = "" }) => {
    const apiURL = handleCreateURL(URL);
    try {
      const response = await axiosInstance.get(`${apiURL}`);
      if (response) {
        return response;
      }
    } catch (error: any) {
      if (error.response.data.status.message === "INVALID_CREDENTIALS") {
        handleLogout();
      }
    }
  };
  const handleGetCallV2 = <T>(url: string): Promise<{ data: T }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(url);
      axiosInstance
        .get(`${apiURL}`)
        .then(({ data }) => {
          resolve({ data: data.data });
        })
        .catch((error: any) => {
          if (
            error.response.data &&
            error.response.data.status.message === "INVALID_CREDENTIALS"
          ) {
            handleLogout();
          }
          reject(error);
        });
    });
  };
  /** Function to handle post requests */
  const handlePostCall = async ({ URL = "", apiParams = {} }) => {
    const apiURL = handleCreateURL(URL);
    try {
      const response = await axiosInstance.post(`${apiURL}`, apiParams);
      if (response) {
        return response;
      }
    } catch (error: any) {
      if (error.response.data.status.message === "INVALID_CREDENTIALS") {
        handleLogout();
      }
    }
  };
  const handlePostCallV2 = <T>({
    URL = "",
    apiParams = {},
    config = {},
  }): Promise<{ data: T; status: any }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(URL);
      axiosInstance
        .post(`${apiURL}`, apiParams, config)
        .then(({ data }) => {
          resolve({ data: data.data, status: data.status });
        })
        .catch((error: any) => {
          if (error.response.data.status.message === "INVALID_CREDENTIALS") {
            handleLogout();
          }
          reject(error);
        });
    });
  };
  /** Function to handle put requests */
  const handlePutCall = async ({
    URL = "",
    apiParams,
  }: {
    URL: string;
    //@ts-ignore
    apiParams;
  }) => {
    //@ts-ignore
    const apiURL = handleCreateURL(URL);
    try {
      const response = await axiosInstance.put(`${apiURL}`, apiParams);
      if (response) {
        return response;
      }
    } catch (error: any) {
      if (error.response.data.status.message === "INVALID_CREDENTIALS") {
        handleLogout();
      }
    }
  };
  const handlePutCallV2 = <T>({
    URL = "",
    apiParams = {},
  }): Promise<{ data: T; status: any }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(URL);
      axiosInstance
        .put(`${apiURL}`, apiParams)
        .then(({ data }) => {
          resolve({ data: data.data, status: data.status });
        })
        .catch((error: any) => {
          if (error.response.data.status.message === "INVALID_CREDENTIALS") {
            handleLogout();
          }
          reject(error);
        });
    });
  };
  const handleDeleteCallV2 = <T>({
    URL = "",
    apiParams = {},
  }): Promise<{ data: T; status: any }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(URL);
      axiosInstance
        .delete(`${apiURL}`, {
          data: apiParams,
        })
        .then(({ data }) => {
          resolve({ data: data.data, status: data.status });
        })
        .catch((error: any) => {
          if (error.response.data.status.message === "INVALID_CREDENTIALS") {
            handleLogout();
          }
          reject(error);
        });
    });
  };
  return {
    handleGetCall,
    handleGetCallV2,
    handlePutCall,
    handlePostCall,
    handlePostCallV2,
    handlePutCallV2,
    handleDeleteCallV2,
  };
};

export default useAxiosClient;
