import React, { useEffect, useState } from "react";
import bill from "../../assets/images/bill.svg";
import { Button, PanelSlider } from "../../components/ui";
import ViewHistory from "./viewHistory";
import useNotifications from "./useNotifications";
import useNotificationsById from "./useNotificationsById";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
import useAxiosClient from "../../hooks/useAxiosClient";
import { toast } from "react-toastify";
import no_data_icon from "../../assets/images/no_data_small.svg";
import NoDataAvailable from "../../components/noDataAvailable";

const Notification: React.FC<{
  setOpenNotifi: boolean;
}> = (setOpenNotifi) => {
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState(0);
  const { fetchNotifications } = useNotifications();

  const { notificationsById, fetchNotificationsById } = useNotificationsById();
  const { handlePostCallV2, handlePutCallV2 } = useAxiosClient();
  const { caId, notifications } = useSelector((state: TRootState) => ({
    caId: state.auth.loginResponse.caId,
    notifications: state.billBookDetails.notifications,
  }));

  useEffect(() => {
    if (open) {
      fetchNotificationsById(stage);
    }
  }, [open]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const onSubmit = (action: string, stageId: number) => {
    handlePutCallV2({
      URL: `/click/bills/bill-edit/stage`,
      apiParams: {
        action: action,
        caId: caId,
        stageId: stageId,
      },
    })
      .then(({ data }) => {
        fetchNotifications();
        // toast.success("Bill Updated successfully");
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.description);
      });
  };

  console.log(notifications);
  return (
    <>
      <div className="w-auto h-auto br-10 p-2 border border-gray m-2">
        {notifications.length > 0 ? (
          notifications.map((notifica) => (
            <div className="py-3 px-2 border-b">
              <div className="flex">
                <div>
                  <img src={bill} alt="bill-img" className="w-18 h-18" />
                </div>
                <div className="ml-2">
                  <p className="text-sm font-bold">{notifica.message}</p>
                  {/* <p className="flex items-center"> */}
                  {/* <span className="text-sm">Edited</span> */}
                  {notifica.edits.slice(0, 3).map((edit, index) => (
                    <span className="text-sm">
                      {index == 0 ? "Edited" : ""} {edit.attribute} :{" "}
                      {edit.oldVal} to {edit.newVal + " | "}
                    </span>
                  ))}
                  {/* </p> */}
                </div>
              </div>

              <div className="mt-2 flex gap-4">
                <Button
                  onClick={() => {
                    onSubmit("CANCEL", notifica.stageId);
                  }}
                  variant="lightRed"
                >
                  DECLINE
                </Button>

                <Button
                  onClick={() => {
                    setOpen(true);
                    setStage(notifica.stageId);
                  }}
                  variant="primaryLight"
                >
                  VIEW HISTORY
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    onSubmit("CONFIRM", notifica.stageId);
                  }}
                >
                  APPROVE CHANGES
                </Button>
              </div>

              {/* <div className="border-b m-2 py-2 w-full"></div> */}
            </div>
          ))
        ) : (
          <div
            className="bg-white items-center justify-center p-3 mt-2 br-10"
            style={{ height: "20.2rem" }}
          >
            <NoDataAvailable />
          </div>
        )}
      </div>

      <div>
        <PanelSlider
          direction="right_to_left"
          open={open}
          style={{ width: "45vw" }}
          showCloseButton={true}
          onClose={() => {
            setOpen(false);
          }}
        >
          {open && (
            <div className="py-6 px-4 border-b flex items-center justify-between">
              <div>
                <span className="font-semibold text-lg">
                  Bill ID : {notificationsById?.edits[0].caBSeq} | Edit
                  Approvals
                </span>
              </div>
            </div>
          )}

          <div className="border-b m-2">
            <ViewHistory stage={stage} />
          </div>

          <div className="flex justify-between px-4 py-2 bottom-0 w-full">
            <div>
              <Button
                onClick={() => {
                  onSubmit("CANCEL", stage);
                  setOpen(false);
                }}
                variant="lightRed"
              >
                {" "}
                DECLINE
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  onSubmit("CONFIRM", stage);
                  setOpen(false);
                }}
                variant="contained"
              >
                {" "}
                APPROVE CHANGES
              </Button>
            </div>
          </div>
        </PanelSlider>
      </div>
    </>
  );
};
export default Notification;
