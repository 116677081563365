import React, { useEffect, useState } from "react";
import bill from "../../assets/images/bill.svg";
import { Button } from "react-bootstrap";
import useNotificationsById from "./useNotificationsById";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GoArrowRight } from "react-icons/go";
import moment from "moment";

const ViewHistory: React.FC<{
  stage: number;
}> = ({ stage }) => {
  const { notificationsById, fetchNotificationsById } = useNotificationsById();
  useEffect(() => {
    if (stage != 0) {
      fetchNotificationsById(stage);
    }
  }, [stage]);

  return (
    <>
      <div className="br-10 px-3 py-3 flex border mx-3 my-3">
        <div className="flex flex-col flex-1">
          <span className="text-xs">Bill To :</span>
          <span className="text-sm">
            {notificationsById?.party.partyName || "-"}
          </span>
        </div>

        <div className="flex flex-col flex-1">
          <span className="text-xs">Address :</span>
          <span className="text-sm">
            {notificationsById?.party.address || "-"}
          </span>
        </div>
        <div className="flex flex-col flex-1">
          <span className="text-xs">Bill Date :</span>
          {notificationsById?.edits !== undefined &&
            notificationsById.edits.length > 1 && (
              <span className="text-sm">
                {notificationsById.edits[0].billDate
                  ? moment(notificationsById.edits[0].billDate).format(
                      "DD-MMM-YY"
                    )
                  : "-"}
              </span>
            )}
        </div>
      </div>

      <div className="flex flex-col px-3">
        <div className="flex bg-light-primary text-sm font-semibold py-2">
          <span className="grow-[1] basis-0 px-2 text-left">Attribute</span>
          <span className="grow-[1] basis-0 px-2">Old</span>
          <span className="grow-[0.5] basis-0 px-2"></span>
          <span className="grow-[1.2] basis-0 px-2">New</span>
          <span className="grow-[1] basis-0 px-2 text-left">Updated by</span>
        </div>
        <div
          className="text-sm overflow-y-scroll"
          style={{ height: "calc(100vh - 18rem)" }}
        >
          {notificationsById?.edits.map((value) => (
            <div className="flex p-2 cursor-pointer border">
              <span className="grow-[1] basis-0 px-2 text-left">
                {value.attribute}
              </span>
              <span className="grow-[1] basis-0 px-2">
                {value.oldVal ? value.oldVal : ""}
              </span>
              <span className="grow-[0.5] basis-0 px-2">
                <GoArrowRight />
              </span>
              <span className="grow-[1.2] basis-0 px-2 flex flex-col">
                <span> {value.newVal ? value.newVal : ""}</span>
                <span className="text-xs">
                  {value?.date
                    ? moment(value?.date).format("DD-MMM-YY | hh:mm:A")
                    : ""}
                </span>
              </span>
              <span className="grow-[1] basis-0 px-2 text-left">
                {value.updatedBy}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default ViewHistory;
