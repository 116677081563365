import { CSSProperties, FC, ChangeEvent } from "react";
import { IoSearchSharp } from "react-icons/io5";
import classes from "./style.module.scss";
const SearchBox: FC<{
  placeholder?: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  style?: CSSProperties;
}> = ({ value, onChange, className = "", style = {}, placeholder = "" }) => {
  return (
    <div className="relative flex items-center overflow-hidden">
      <IoSearchSharp className="absolute" style={{ left: 5 }} />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`text-sm py-1 w-full ${classes.searchBoxInput} ${className}`}
        style={{ ...style }}
      />
    </div>
  );
};
export default SearchBox;
