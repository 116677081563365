import { FC, ChangeEvent, FocusEvent, CSSProperties } from "react";
import classes from "./style.module.scss";
const InputV2: FC<{
  label?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
  style?: CSSProperties;
  allowNegativeNumber?: boolean;
}> = ({
  label,
  type = "text",
  value = "",
  className,
  placeholder,
  onChange,
  allowNegativeNumber = false,
}) => {
  return (
    <input
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        if (!onChange) {
          return;
        }
        if (type === "number") {
          let regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
          if (allowNegativeNumber === true) {
            regexp = /^[-0-9]*(\.[0-9]{0,2})?$/;
          }
          if (regexp.test(e.target.value) == true) {
            onChange(e);
          } else {
            e.target.value = (e.target.value);
            onChange(e);
          }
        } else {
          onChange(e);
        }
      }}
      className={`h-10 ${classes.input} ${className}`}
      onWheel={(e) => (e.target as HTMLElement).blur()}
    />
  );
};
export default InputV2;
