import React, { forwardRef } from "react";
import classes from "./style.module.scss";

const Input = (
  {
    label,
    type = "text",
    className = "",
    id = "",
    error = "",
    required,
    styles,
    containerStyle = {},
    ...props
  },
  ref
) => {
  return (
    <div className={classes.container} style={containerStyle}>
      {label && (
        <label htmlFor={id} className={classes.label}>
          {label} <span data-required={required}></span>
        </label>
      )}
      <div className={classes.inputContainer} style={styles}>
        <input
          type={type}
          className={`${classes.input} ${className}`}
          ref={ref}
          id={id}
          data-error={error.length > 0}
          {...props}
        />

        {error.length > 0 && (
          <span className={classes.errorMessage}>{error}</span>
        )}
      </div>
    </div>
  );
};

export default forwardRef(Input);
